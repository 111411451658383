import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
} from "@material-ui/core";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { addDoc, collection } from "firebase/firestore/lite";
import emailjs from "emailjs-com";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import BookAnimation from "./BookAnimation";
import db from "../Pages/Components/firebase.config";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_USER_ID;

const useStyles = makeStyles((theme) => ({
  mainContainerWrapper: {
    marginTop: "0.5rem",
    padding: "2rem 5rem ",
    background: "#15315e",
    marginBottom: "0.5rem",
    fontFamily: "'Manrope', sans-serif",
    [theme.breakpoints.down("md")]: {
      minHeight: "140px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "4px",
    },
  },
  MobileViewContainer: {
    display: "flex",
    justifyContent: "center",
  },
  contentWraper: {
    userSelect: "none",
    color: "White",
    padding: "3rem 0rem 3rem 0rem",
    fontFamily: '"Manrope", sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
      padding: "1rem 0rem",
      textAlign: "justify",
      wordSpacing: "-0.1em",
      hyphens: "auto",
      wordSpacing: "-0.1em",
      hyphens: "auto",
      lineHeight: "1.4",
    },
  },
  mobView: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 8px",
    },
  },
  formikField: {
    border: "1px solid #000",
  },
  subsciptionContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "0px !important",
    },
  },
  subscribeButton: {
    animation: `$float 2s ease-in-out infinite, $blink 1.5s ease-in-out infinite`,
    width: "70%",
    borderRadius: "15px",
    padding: "10px 10px",
    fontSize: "18px",
    textTransform: "unset",
    fontWeight: "bold",
  },
  "@keyframes float": {
    "0%, 100%": {
      transform: "translateY(0)",
    },
    "50%": {
      transform: "translateY(10px)",
    },
  },
  "@keyframes blink": {
    "0%, 100%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.5,
    },
  },
}));
const NewsLetter = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const className = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [emailStatusMessage, setEmailStatusMessage] = useState(false);

  async function sendEmail(email, name) {
    const message = `Thank you for subscribing to our newsletter!.`;
    emailjs
      .send(
        SERVICE_ID,
        TEMPLATE_ID,
        {
          from_name: "Senwell",
          to_email: email,
          to_name: name,
          company_name: "Senwell Group Private Limited",
          message: message,
        },
        USER_ID
      )
      .then((response) => {
        console.log("Email sent successfully");
        setEmailStatusMessage(true);
        return true;
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setEmailStatusMessage(false);
        return false;
      });
  }

  const handleSubmit = async (data, { resetForm }) => {
    try {
      setIsLoading(true);
      const now = new Date();
      const currentDate = now.toLocaleDateString();
      const currentTime = now.toLocaleTimeString();
      const enhancedData = {
        ...data,
        date: currentDate,
        time: currentTime,
      };
      const contactsRef = collection(db, "newsletter_contacts");
      const emailSucess = await sendEmail(data.email, data.name);
      if (emailSucess || emailStatusMessage)
        await addDoc(contactsRef, enhancedData);
      setShowSuccess(true);
    } catch (error) {
      setEmailStatusMessage(false);
      console.error("Error sending message:", error);
      setOpen(false);
    } finally {
      setTimeout(() => {
        setOpen(false);
        setShowSuccess(false);
      }, 1000);
      resetForm();
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    companyName: Yup.string().optional("companyName"),
    message: Yup.string().required("Message is required"),
  });

  const images2024 = [
    {
      src: "/images/newsletters/2024/01.jpg",
      alt: "Senwell 2024-01",
    },
  ];
  const image = "";

  return (
    <>
      <div
        className={className.mainContainerWrapper}
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <Container className={className.mobView} maxWidth="lg">
          <Grid container className={className.MobileViewContainer} spacing={3}>
            <Grid
              item
              xs={10}
              md={8}
              lg={8}
              className={className.MobileViewContainer}
            >
              <Typography variant="h4" className={className.contentWraper}>
                Stay Updated with Our Newsletters
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              md={4}
              lg={4}
              style={{ display: "flex" }}
              justifyContent="center"
              alignItems="center"
              className={className.subsciptionContainer}
            >
              <Button
                className={className.subscribeButton}
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Subscribe
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Dialog
          open={open}
          maxWidth={"md"}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          {showSuccess ? (
            <div
              className="p-4"
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <h3 className="p-41">
                {emailStatusMessage
                  ? "Email Sent Successfully"
                  : "Please try again after some time"}{" "}
              </h3>
              {emailStatusMessage ? (
                <CheckIcon style={{ color: "green", fontSize: "50px" }} />
              ) : (
                <CloseIcon style={{ color: "red", fontSize: "50px" }} />
              )}
            </div>
          ) : (
            <>
              <DialogTitle className="px-3 fs-2 text-center fw-bolder">
                <h3>Fill Details For Newsletter</h3>
              </DialogTitle>
              <CloseIcon
                className="position-absolute"
                sx={{ fontSize: "30px", right: "0", cursor: "pointer" }}
                onClick={handleClose}
              />
              <Divider style={{ height: "2px" }} />
              <DialogContent className="px-5 pb-5">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <BookAnimation />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        message: "",
                        companyName: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values, { resetForm }) => {
                        handleSubmit(values, { resetForm });
                      }}
                    >
                      {({ errors, touched, isSubmitting }) => (
                        <Form>
                          <Field
                            as={TextField}
                            fullWidth
                            variant="filled"
                            className="formikField"
                            label="Name"
                            name="name"
                            error={touched.name && !!errors.name}
                            helperText={touched.name && errors.name}
                            margin="dense"
                          />
                          <Field
                            as={TextField}
                            fullWidth
                            variant="filled"
                            className="formikField"
                            label="Email"
                            name="email"
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            margin="dense"
                          />
                          <Field
                            as={TextField}
                            fullWidth
                            variant="filled"
                            className="formikField"
                            label="Company Name"
                            name="companyName"
                            error={touched.companyName && !!errors.companyName}
                            helperText={
                              touched.companyName && errors.companyName
                            }
                            margin="dense"
                          />
                          <Field
                            as={TextField}
                            fullWidth
                            variant="filled"
                            multiline
                            className="formikField"
                            rows={4}
                            label="Message"
                            name="message"
                            error={touched.message && !!errors.message}
                            helperText={touched.message && errors.message}
                            margin="dense"
                          />
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting}
                            className="w-100 flex"
                          >
                            {isLoading ? (
                              <i className="fa fa-spinner fa-spin"></i>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </DialogContent>
            </>
          )}
        </Dialog>
      </div>
    </>
  );
};

export default NewsLetter;

import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const ImageSlider = ({ images, interval = 6000, cancelOnInteraction = false}) => {
  return (
    <AutoplaySlider
      play={true}
      cancelOnInteraction={cancelOnInteraction}
      interval={interval}
      animation="cubeAnimation"
      bullets={false}
      transitionDelay={0}
    >
      {images.map((image, index) => (
        <div key={index} data-src={image} />
      ))}
    </AutoplaySlider>
  );
};

export default ImageSlider;

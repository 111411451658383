import {
  Box,
  Button,
  Container,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import { Close } from "@material-ui/icons";
import React, { useState } from "react";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { useFormik } from "formik";
import * as yup from "yup";
import { addDoc, collection } from "firebase/firestore/lite";
import HeroBanner from "../../Modules/SliderText";
import { textAlign } from "@mui/system";
import { Stack } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import db from "../Components/firebase.config";
import { Link, useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getDownloadURL } from "firebase/storage";
import { useLocation } from "react-router-dom";
import Notification from "../Notification/Notification";

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_USER_ID;

const useStyle = makeStyles((theme) => ({
  applyHeading: {
    maxWidth: "665px",
    fontFamily: " 'Ubuntu', sans-serif",
    textAlign: "start",
    margin: "0 auto",
    paddingBottom: "1rem",
    marginTop: "6rem",
    padding: "1rem 4rem",
    marginBottom: "5rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "20px",
    boxShadow: "0 0 10  px black",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1rem",
      marginTop: "4rem",
    },
  },

  TextField: {
    marginBottom: "1rem",
    "&:focus": {
      outline: "none",
    },
    "&:label": {
      fontWeight: "700",
    },
  },
  titleWrapper: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  title: {
    fontFamily: " 'Ubuntu', sans-serif",
    paddingBottom: "2rem",
    textAlign: "center",
    color: "black",
    fontWeight: "900",
  },

  applybtn: {
    color: "#fff",
    letterSpacing: "2.5px",
    fontSize: "1rem",
    fontWeight: "600",
    fontFamily: "'Manrope', sans-serif",
    border: "2px solid rgb(31 142 203)",
    background:
      "linear-gradient(90deg, hsla(200, 69%, 50%, 1) 24%, hsla(216, 62%, 39%, 1) 83%)",
    "&:hover": {
      color: "black",
      border: "2px solid #fff",
    },
  },
  uploadbtn: {
    justifyContent: "start",
  },
  progressBar: {
    height: "15px",
    overflow: "hidden",
    position: "relative",
    borderRadius: "10px",
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "rgb(31, 142, 203)",
    },
  },
  fileUploader: {
    minWidth: "auto !important",
  },
  gender: {
    marginLeft: "10px",
  },
  experience: {
    marginLeft: "10px",
  },
}));

const validationSchema = yup.object({
  name: yup.string("Enter your name").required("name is required"),
  number: yup.string("Enter your number").required("number is required"),

  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  gender: yup.string("Select your gender").required("Gender is required"),
  experience: yup
    .string("Enter your experience")
    .required("Experience is required"),
  location: yup.string("Enter your location").required("Location is required"),
});

const fileTypes = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export default function Resumedata() {
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const designation = location.state?.designation;

  const [file, setFile] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  function sendEmail(email, name) {
    const message = `\nThank you for applying to Senwell. We appreciate your interest and time in applying with us.
    Our Talent Acquisition Team will soon get in touch with you. Meanwhile, Follow us on LinkedIn at https://www.linkedin.com/company/senwell-soutions/mycompany/ `;
    emailjs
      .send(
        SERVICE_ID,
        TEMPLATE_ID,
        {
          from_name: "Senwell",
          company_name:"Senwell Group Private Limited",
          to_email: email,
          to_name: name,
          message: message,
        },
        USER_ID
      )
      .then((response) => {
        console.log("Email sent:", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  }

  const classNamees = useStyle();
  let navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      number: "",
      file: "",
      gender: "",
      experience: "",
      location: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await addDoc(collection(db, "applicants"), {
          name: values.name,
          email: values.email,
          number: values.number,
          resume: url,
          jobId: "RKaYv1nRzwJZQ3bASuQO",
          appliedOn: new Date(),
          jobRole: designation,
          gender: values.gender,
          experience: values.experience,
          location: values.location,
        });
        sendEmail(values.email, values.name);
        resetForm();
        // setOpenModal(true);
        navigate("/thankyou");
      } catch (err) {
        alert(err);
      }
    },
  });

  const [url, setURL] = useState("");

  const handleChange = async (file) => {
    const allowedFileTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (!allowedFileTypes.includes(file[0].type)) {
      alert("Please select a valid PDF or DOCX file.");
      return;
    }

    setFile(file);
    const storage = getStorage();
    const storageRef = ref(
      storage,
      "resume/" + new Date().getTime() + file[0].name.trim().replace(/\s/g, "")
    );

    const uploadTask = uploadBytesResumable(storageRef, file[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("File upload error:", error);
      },
      () => {
        getDownloadURL(storageRef)
          .then((downloadURL) => {
            setURL(downloadURL);
            setUploadProgress(100);
          })
          .catch((error) => {
            console.error("Error getting download URL:", error);
          });
      }
    );
  };

  return (
    <>
      <div
        className="resumejumbotron resumeparallax"
        id="resumeparallax-static"
      >
        <div className="showcasebanner">
          <div className="overlaybanner">
            <Container maxWidth="sm">
              <Box className={classNamees.applyHeading}>
                <Typography variant="h5" className={classNamees.title}>
                  Apply for a job
                </Typography>

                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    inputProps={{
                      style: { padding: 14 },
                    }}
                    id="name"
                    name="name"
                    label=" Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    className={classNamees.TextField}
                    variant="outlined"
                  />

                  <TextField
                    inputProps={{
                      style: {
                        padding: 14,
                      },
                    }}
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    className={classNamees.TextField}
                    variant="outlined"
                  />

                  <TextField
                    inputProps={{
                      style: {
                        padding: 14,
                      },
                    }}
                    fullWidth
                    id="number"
                    name="number"
                    label="Number"
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.number && Boolean(formik.errors.number)
                    }
                    helperText={formik.touched.number && formik.errors.number}
                    className={classNamees.TextField}
                    variant="outlined"
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TextField
                      style={{ width: "45%" }}
                      inputProps={{
                        style: { padding: 14 },
                      }}
                      id="gender"
                      name="gender"
                      label="Gender"
                      select
                      variant="outlined"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.gender && Boolean(formik.errors.gender)
                      }
                      helperText={formik.touched.gender && formik.errors.gender}
                      className={classNamees.TextField}
                    >
                      <MenuItem className={classNamees.gender} value="male">
                        Male
                      </MenuItem>
                      <MenuItem className={classNamees.gender} value="female">
                        Female
                      </MenuItem>
                    </TextField>

                    <TextField
                      style={{ width: "50%" }}
                      inputProps={{
                        style: { padding: 14 },
                      }}
                      id="experience"
                      name="experience"
                      label="Experience"
                      select
                      variant="outlined"
                      value={formik.values.experience}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.experience &&
                        Boolean(formik.errors.experience)
                      }
                      helperText={
                        formik.touched.experience && formik.errors.experience
                      }
                      className={classNamees.TextField}
                    >
                      <MenuItem
                        className={classNamees.experience}
                        value="lessthan1"
                      >
                        less than 1 year
                      </MenuItem>
                      <MenuItem className={classNamees.experience} value="1to2">
                        1-2 year
                      </MenuItem>
                      <MenuItem className={classNamees.experience} value="2to3">
                        2-3 year
                      </MenuItem>
                      <MenuItem
                        className={classNamees.experience}
                        value="morethan3"
                      >
                        more than 3 year
                      </MenuItem>
                    </TextField>
                  </div>
                  <TextField
                    fullWidth
                    inputProps={{
                      style: { padding: 14 },
                    }}
                    id="location"
                    name="location"
                    label="Location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.location && Boolean(formik.errors.location)
                    }
                    helperText={
                      formik.touched.location && formik.errors.location
                    }
                    className={classNamees.TextField}
                    variant="outlined"
                  />

                  <FileUploader
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    type={fileTypes}
                    label="Please choose PDF or DOCX file(s)"
                    required
                    classes={classNamees.fileUploader}
                  />
                  <p style={{ color: "black" }}>
                    {file
                      ? `File name: ${file[0].name}`
                      : "No files uploaded yet"}
                  </p>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                    style={{ marginBottom: "16px" }}
                    className={classNamees.progressBar}
                  />

                  <Button
                    className={classNamees.applybtn}
                    color="primary"
                    disabled={!url}
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Apply
                  </Button>
                </form>
              </Box>
            </Container>
          </div>
        </div>
      </div>
      <Notification openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}

import React from "react";
import Typewriter from "typewriter-effect";
import "./Slider.css";

export default function SliderDemo() {
  return (
    <div className="bg-video-wrap">
      {/* Fullscreen Video */}
      <iframe
        className="video-bg"
        src="https://player.vimeo.com/video/870167957?badge=0&autopause=0&background=1&loop=1&muted=1&autoplay=1"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        title="background-video"
      ></iframe>

      {/* Overlay Text */}
      <div className="text-overlay">
        <p className="subheading">Discover a New Vision for Your Business Solutions</p>
        <h1 className="main-heading">
          We{" "}
          <span>
            <Typewriter
              options={{
                autoStart: true,
                loop: true,
                delay: 40,
                strings: ["Create", "Build", "Digitize"],
              }}
            />
          </span>
        </h1>
        <p className="subheading">
          The next stage of your digital success begins right here with Senwell Group Private Limited.
        </p>
      </div>
    </div>
  );
}

import React from "react";
import { InlineWidget } from "react-calendly";
import "./ScheduleCall.css";
import bannerImage from "../../images/Blogs/bannerImage.jpg";

const CustomScheduleCall = () => {
  return (
    <div className="custom-wrapper">
      <div className="custom-container">
        {/* Image Section */}
        <div className="custom-image-section">
          <img src={bannerImage} alt="Banner" className="custom-image" />
        </div>

        {/* Calendly Section */}
        <div className="custom-calendly-section">
          <InlineWidget
            url="https://calendly.com/senwellexports-marketing/30min"
            styles={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomScheduleCall;
